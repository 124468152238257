import React from 'react'
import { Row } from '../../../../../components/RNWrapper'
import { MyText } from '../../../../../components/MyText'
import { c, sp } from '../../../../../styles/Style'
import { Button } from 'antd'
import { IconButton } from '../../../../../components/IconButton'
import { FilterOutlined } from '@ant-design/icons/lib'
import Modal from '../../../../../app/modal/Modal'
import { Filter } from '../../Filter'
import { confirmDeletePosts } from '../../State'

export const SearchHeader = (p: { selectedPosts: string[] }) => {
  return (
    <Row style={{ alignItems: 'center', backgroundColor: '#001122', padding: sp.sm }}>
      <IconButton onPress={() => Modal.render(<Filter />)}>
        <FilterOutlined style={{ color: c.white }} />
      </IconButton>
      <MyText style={{ color: c.white, marginLeft: sp.sm }}>
        You have selected <b>{p.selectedPosts.length}</b> posts
      </MyText>
      <Button style={{ marginLeft: 'auto' }} type={'danger'} onClick={() => confirmDeletePosts(p.selectedPosts)}>
        Delete
      </Button>
    </Row>
  )
}
