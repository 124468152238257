import { navigate } from '@reach/router'

// interface Paths {
//   index: '/',
//   results: (id: string) => {
//     return `/results/${id}`
//   })
// }

const NavState: Map<string, any> = new Map()

class Nav {
  async href<T = any>(str: string, state?: T): Promise<void> {
    if (state) {
      NavState.set(str, state)
    }
    return navigate(str)
  }

  state<T = any>(str: string): T {
    return NavState.get(str)
  }

  url_tab(str: string): Window | null {
    var a = window.open(str, '_blank', 'width=720,height=560')
    console.log(a)
    return a
  }
}
export default new Nav()
