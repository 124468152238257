import { Post } from 'reddit-ts/src/types/models/Post.type'
import ReactMarkdown from 'react-markdown'
import Column from 'antd/es/table/Column'
import { Row, View } from '../../../../components/RNWrapper'
import { MyText } from '../../../../components/MyText'
import { c, sp, sz, wt } from '../../../../styles/Style'
import { Date_ } from '@aelesia/commons'
import { Print } from '../../../../utils/Print'
import { Table } from 'antd/es'
import React, { Dispatch, SetStateAction } from 'react'

export const PostTable = (p: {
  posts: Post[]
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
}) => {
  return (
    <Table<Post>
      dataSource={p.posts}
      rowKey="id"
      showHeader={false}
      pagination={{ pageSize: 10 }}
      size={'middle'}
      rowSelection={{
        selectedRowKeys: p.selected,
        onChange: selectedRowKeys => {
          p.setSelected(selectedRowKeys as string[])
        }
      }}
      onRowClick={record => {
        if (!p.selected.some(it => record.id === it)) {
          p.setSelected(prev => [...prev, record.id])
        } else {
          p.setSelected(prev => prev.filter(it => it !== record.id))
        }
      }}
      expandedRowRender={post => {
        return <ReactMarkdown>{post.body}</ReactMarkdown>
      }}
    >
      <Column<Post>
        render={(text, post) => {
          return (
            <Row style={{ alignItems: 'center' }}>
              <View style={{ minWidth: 120 }}>
                <MyText style={{ fontSize: sz.sm }}>{Date_._f(post.date, 'DD MMM YY')}</MyText>
                <MyText style={{ fontStyle: 'italic' }}>/r/{post.subreddit}</MyText>
              </View>
              <View>
                <Row>
                  <a href={post.url} target="_blank" rel="noopener noreferrer">
                    <MyText style={{ color: c.link, fontSize: sz.sm, fontWeight: wt.light }}>{post.title}</MyText>
                  </a>
                </Row>
                <MyText style={{ marginTop: sp.xs }}>{Print.ellipsis(post.body, 100)}</MyText>
              </View>
            </Row>
          )
        }}
      />
    </Table>
  )
}
