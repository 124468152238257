import { Date_, Str } from '@aelesia/commons'

function date(text: string | Date): string
function date(text: unknown): string {
  // HACK: All API dates should be formatted properly. Please fix.
  if (typeof text === 'string') {
    return Date_._f(new Date(text), 'DD MMM YYYY')
  }
  if (text instanceof Date) {
    return Date_._f(text, 'DD MMM YYYY')
  }
  console.warn(`Unable to parse ${text} to Date`)
  return 'ERR'
}
function ellipsis(text: string, length: number): string
function ellipsis(text: unknown, length: number): string {
  if (typeof text === 'string') {
    return text.length <= length ? text : text.substr(0, length) + '...'
  }
  console.warn(`Unable to parse ${text} to String`)
  return 'ERR'
}

function strip_markdown(text: string): string
function strip_markdown(text: unknown): string {
  if (typeof text === 'string') {
    return Str.remove(text, ['\\*\\*', '_'])
  }
  console.warn(`Unable to parse ${text} to String`)
  return 'ERR'
}

export const Print = { date, ellipsis, strip_markdown }
