import React, { CSSProperties, useState } from 'react'
import { Button, Input } from 'antd'
import { Row } from '../../../components/RNWrapper'
import { sp } from '../../../styles/Style'

type P = {
  style?: CSSProperties
  onSearch: (username: string) => void
}
export const SearchBar = (p: P) => {
  const [username, setUsername] = useState<string>('')

  return (
    <Row style={p.style}>
      <Input
        size="large"
        placeholder="Enter your Reddit Username"
        onChange={it => {
          setUsername(it.target.value)
        }}
        onPressEnter={() => p.onSearch(username)}
      />
      <Button
        style={{ marginLeft: sp.sm }}
        size="large"
        type={'primary'}
        onClick={() => p.onSearch(username)}
        disabled={username.length < 4}
      >
        Search
      </Button>
    </Row>
  )
}
