import React from 'react'
import { Row, View } from '../../../components/RNWrapper'
import { withStyle } from 'reactjs-commons'
import { my, sp, wt } from '../../../styles/Style'
import { MyText } from '../../../components/MyText'
import { SearchBar } from './SearchBar'
import Nav from '../../../app/navigation/Nav'
import Path from '../../../app/navigation/Path'

export const Landing = () => {
  return (
    <Row style={{ height: '100vh' }}>
      <View
        style={{
          width: 360,
          justifyContent: 'space-between',
          alignSelf: 'center',
          margin: sp.sm
        }}
      >
        <Title>Want to clear your Reddit history?</Title>
        <Description>Reddit Eraser is the fastest way to delete your posts</Description>
        <SearchBar
          onSearch={username => {
            Nav.href(Path.results(username))
          }}
          style={{ margin: my.xs }}
        />
      </View>
    </Row>
  )
}

const Title = withStyle(MyText)({
  fontSize: 32,
  fontWeight: wt.black,
  margin: my.xs
})

const Description = withStyle(MyText)({
  fontWeight: wt.light,
  margin: my.xs
})
