import { Dispatch, SetStateAction, useEffect, useState } from 'react'

function calculateValue<T>(setState: SetStateAction<T>, state: T): T {
  if (setState instanceof Function) {
    return setState(state)
  } else {
    return setState
  }
}

type State = number | boolean | string | Object
export class LinkedState<T extends State> {
  _state: T

  constructor(initialValue: T) {
    this._state = initialValue
  }

  set(setState: SetStateAction<T>): void {
    this._state = calculateValue(setState, this._state)
    this._observeList.forEach(observer => {
      observer(this._state)
    })
  }

  state(): T {
    return this._state
  }

  _observeList: Dispatch<SetStateAction<T>>[] = []

  _observe(setState: Dispatch<SetStateAction<T>>) {
    setState(this._state)
    this._observeList.push(setState)
  }

  _unobserve(setState: Dispatch<SetStateAction<T>>) {
    this._observeList = this._observeList.filter(it => it != setState)
  }

  // return [state, setGlobalState, setLocalState]
  useState(initialState: T): [T, Dispatch<SetStateAction<T>>, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState<T>(initialState)
    useEffect(() => {
      this._observe(setState)
      return () => this._unobserve(setState)
      // I cannot forsee what circumstances key will ever be dynamic,
      //   given that the schema is pre-defined, as such I'm disabling changes on it.
    }, [])
    return [
      state,
      a => {
        this.set(a)
      },
      setState
    ]
  }
}

export function useLinkedState<T>(
  linkedState: LinkedState<T>
): [T, Dispatch<SetStateAction<T>>, Dispatch<SetStateAction<T>>] {
  const [state, setLocalState] = useState<T>(linkedState.state())
  const setGlobalState: Dispatch<SetStateAction<T>> = a => {
    linkedState.set(a)
  }
  useEffect(() => {
    linkedState['_observe'](setLocalState)
    return () => linkedState['_unobserve'](setLocalState)
    // I cannot forsee what circumstances key will ever be dynamic,
    //   given that the schema is pre-defined, as such I'm disabling changes on it.
  }, [])
  return [state, setLocalState, setGlobalState]
}

export class ComputedLinkedState<T, U> {
  _linkedState: LinkedState<T>
  _reduxed: (state: T) => U

  constructor(linkedState: LinkedState<T>, reduxed: (state: T) => U) {
    this._linkedState = linkedState
    this._reduxed = reduxed
  }

  state(): U {
    return this._reduxed(this._linkedState.state())
  }

  _observeList: Dispatch<SetStateAction<U>>[] = []
  _observe(setState: Dispatch<SetStateAction<U>>) {
    setState(this.state())
    this._observeList.push(setState)
  }
  _unobserve(setState: Dispatch<SetStateAction<U>>) {
    this._observeList = this._observeList.filter(it => it != setState)
  }
}

// function ReduxedLinkState<T, U>(
//   linkedState: LinkedState<T>,
//   reduxed: (id: string, state: T) => U,
//   reduxSet: (id: string, state: T, value: U) => T
// ) {
//   return (id: string) => {
//     return new _ReduxedLinkState(id, linkedState, reduxed, reduxSet)
//   }
// }

export function useComputedState<T, U>(rLinkState: ComputedLinkedState<T, U>, depList: any[] = []) {
  const [state, setLocalState] = useState<U>(rLinkState.state())

  useEffect(() => {
    rLinkState._observe(setLocalState)
    return () => rLinkState._unobserve(setLocalState)
  }, [])
  return state
}
