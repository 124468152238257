import { Post } from 'reddit-ts/src/types/models/Post.type'
import Httyp from 'httyp'
import Cfg from '../app/Config'
import axios from 'axios'
import { Notify } from '../app/notify/Notify'

export function isDateISOString(str: string) {
  return str.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T([0-9]{2}:){2}[0-9]{2}\.[0-9]{3}Z/)
}

function convert_iso_to_date_obj<T extends object>(obj: object): T {
  return JSON.parse(JSON.stringify(obj), (key: any, value: any) => {
    if (typeof value == 'string' && isDateISOString(value)) {
      return new Date(value)
    }
    return value
  })
}

const myaxios = axios.create()
myaxios.interceptors.response.use(
  response => convert_iso_to_date_obj(response),
  error => {
    Notify.error({ message: `${error.name} ${error.response?.status}`, description: error.message })
    return Promise.reject(error)
  }
)
const Http = Httyp.axios(myaxios).url(Cfg.BACKEND)

class API {
  async search(username: string): Promise<Post[]> {
    return (
      await Http.path('/search')
        .params({ username })
        .get<Post[]>()
    ).data
  }

  // TODO: Don't use axios
  async delete(posts: string[], code: string): Promise<DelResponse[]> {
    return (await axios.post<DelResponse[]>(`${Cfg.BACKEND}/delete`, { posts, code })).data
  }
}
export default new API()
