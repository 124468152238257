import React from 'react'
import 'antd/dist/antd.css'
import { Main } from './pages/Main'
import { MyModal } from './app/modal/MyModal'
import Modal from './app/modal/Modal'

const App: React.FC = () => {
  return (
    <>
      <MyModal ref={ref => Modal.setModal(ref)} />
      <Main />
    </>
  )
}

export default App
