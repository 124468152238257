class Path {
  index = '/'
  results(id: string) {
    return `/results/${id}`
  }
  summary = '/summary'
  oauth = '/oauth'
}

export default new Path()
