import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Button, Icon, List, Result, Spin } from 'antd'
import Text from 'antd/es/typography/Text'
import { c } from '../../styles/Style'
import Path from '../../app/navigation/Path'
import API from '../../services/API'
import RedditController from '../../app/reddit/RedditController'
import { View } from '../../components/RNWrapper'
import Nav from '../../app/navigation/Nav'
import { Throw } from '@aelesia/commons'
import { IllegalArgumentErr } from '@aelesia/commons/dist/src/error/Error'

type P = {
  delete_list: string[]
} & RouteComponentProps

export const SummaryPage = (p: RouteComponentProps) => {
  const deleteList = Nav.state(p.path ?? Throw(new IllegalArgumentErr('deleteList not passed in')))
  return <Summary delete_list={deleteList} />
}

export const Summary: React.FC<P> = ({ delete_list }) => {
  const [response, setResponse] = useState<DelResponse[] | undefined>(undefined)
  useEffect(() => {
    delete_posts(delete_list)
  }, [])

  async function delete_posts(list: string[]) {
    let resp = await API.delete(list, await RedditController.get_token())
    setResponse(resp)
  }

  function errors(data: DelResponse[]): DelResponse[] {
    return data.filter(it => {
      return it.status === 'error'
    })
  }

  function link(data: DelResponse | DelResponse[]): string {
    if (data instanceof Array) return `https://www.reddit.com/api/info?id=${data.map(it => it.id)}`
    else return `https://www.reddit.com/api/info?id=${data.id}`
  }

  if (response == null) {
    return <Result icon={<Spin size="large" />} title="Deleting" subTitle={'Please wait awhile'} />
  } else if (errors(response).length === 0) {
    return (
      <Result
        status="success"
        title={`Successfully deleted ${delete_list.length} posts`}
        extra={[
          <Button key={0} type={'primary'} href={Path.index}>
            New search
          </Button>,
          // TODO: Link back properly
          <Button key={1} type={'primary'} ghost href={'https://www.reddit.com'}>
            View your profile
          </Button>
        ]}
      />
    )
  } else if (errors(response).length > 0) {
    return (
      <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Result
          status="warning"
          title={`Some posts could not be deleted`}
          subTitle={`${errors(response).length} out of ${delete_list.length} were unsuccessful`}
          extra={[
            <Button key={0} type={'primary'} onClick={() => delete_posts(errors(response).map(it => it.id))}>
              Retry
            </Button>,
            <Button key={1} type={'primary'} ghost href={link(response)} target={'_blank'}>
              View All
            </Button>
          ]}
        />

        <List
          style={{ width: 480 }}
          dataSource={errors(response)}
          bordered={true}
          renderItem={item => (
            <List.Item style={{ display: 'flex', flexDirection: 'row' }}>
              <Button style={{ width: 150 }} type={'link'} href={link(item)} target={'_blank'}>
                Post #{item.id}
              </Button>
              <View style={{ display: 'flex', alignItems: 'center' }}>
                <Icon type="close-circle" style={{ color: c.red5 }} />
                <Text style={{ paddingLeft: 5 }}>
                  {item.error?.name}: {item.error?.message}
                </Text>
              </View>
            </List.Item>
          )}
        />
      </View>
    )
  } else {
    return <Result status="error" title={`An error has occured`} />
  }
}
