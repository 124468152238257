import React, { useEffect } from 'react'
import { Searching } from './Searching/Searching'
import { SearchResults } from './SearchResults/SearchResults'
import { Post } from '../../../model/Post'
import { fetchPosts, FilteredPosts } from './State'
import { useLinkedState } from '../../../utils/LinkedState'

export const Search = (p: { id: string }) => {
  const [posts] = useLinkedState<Post[]>(FilteredPosts)

  useEffect(() => {
    fetchPosts(p.id)
  }, [])

  if (posts.length > 0) {
    return <SearchResults posts={posts} />
  }
  return <Searching />
}
