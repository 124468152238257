import { Modal, Spin } from 'antd'
import React from 'react'
import Cfg from '../Config'
import { Date_, Time } from '@aelesia/commons'

type Result = { state: string; code: string } | { state: string; error: 'access_denied' }

class RedditController {
  res: (value?: PromiseLike<string> | string) => void = undefined as any
  rej: (reason?: any) => void = undefined as any
  token: { code: string; exp_on: Date } | undefined
  new_window: Window | null = null
  listener: any

  async get_token(): Promise<string> {
    if (this.token && !Date_.isPast(this.token.exp_on)) {
      return this.token.code
    }

    this.open_authorization_page()
    Modal.info({
      title: 'Authorizing with Reddit',
      okText: 'Cancel',
      okType: 'ghost',
      onOk: () => {
        window.removeEventListener('message', this.listener)
        this.new_window?.close()
      },
      content: <Spin />
    })
    return new Promise((res, rej) => {
      this.res = res
      this.rej = rej
    })
  }

  open_authorization_page() {
    this.new_window = window.open(
      `https://www.reddit.com/api/v1/authorize?client_id=${Cfg.CLIENT_ID}&response_type=code&state=RANDOM_STRING&redirect_uri=${Cfg.REDIRECT_URL}&duration=temporary&scope=edit`,
      '_blank',
      'width=720,height=560'
    )
    this.listener = (event: any) => {
      let result: Result = event.data
      if ('code' in result) {
        this.token = {
          code: result.code,
          exp_on: Date_.add(Time.hours(1), Date_.now())
        }
        this.res(result.code)
        Modal.destroyAll()
      } else {
        this.rej(result.error)
        Modal.destroyAll()
        Modal.error({
          title: 'Authorization Failed',
          content: result.error
        })
      }
    }
    window.addEventListener('message', this.listener)
  }
}

export default new RedditController()
