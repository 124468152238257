import React from 'react'
import { Checkbox, Collapse, DatePicker } from 'antd'
import { useComputedState } from '../../../utils/LinkedState'
import { filterByDate, filterSubreddits, UniqueSubreddits } from './State'

const { RangePicker } = DatePicker

const { Panel } = Collapse

export const Filter = () => {
  const unique = useComputedState(UniqueSubreddits)

  return (
    <Collapse>
      <Panel header="Subreddit" key="1">
        <Checkbox.Group
          options={unique}
          defaultValue={unique}
          onChange={selectedSubreddits => {
            filterSubreddits(selectedSubreddits as string[])
          }}
        />
      </Panel>
      <Panel header="Date" key="2">
        <RangePicker
          onChange={dates => {
            if (dates[0] && dates[1]) {
              filterByDate(dates[0].toDate(), dates[1].toDate())
            }
          }}
        />
      </Panel>
    </Collapse>
  )
}
