import React from 'react'
import { Router } from '@reach/router'
import { Page } from '../components/Page'
import Path from '../app/navigation/Path'
import { Search } from './v2/Search/Search'
import { Landing } from './v2/Landing/Landing'
import { Oauth } from './oauth/Oauth'
import { SummaryPage } from './summary/Summary'
import { View } from '../components/RNWrapper'

export const Main: React.FC = () => {
  return (
    <View style={{ width: '100%', alignItems: 'center' }}>
      <View style={{ maxWidth: 1080 }}>
        <View style={{ flexGrow: 1 }}>
          <Router>
            <Page path={Path.index} component={Landing} />
            <Page path={Path.results(':id')} id={':id'} component={Search} />
            <Page path={Path.summary} component={SummaryPage} />
            <Oauth path={Path.oauth} />
          </Router>
        </View>
      </View>
    </View>
  )
}
