require('dotenv')

class Config {
  // TODO: ENV configs please
  readonly BACKEND = Config.dotenv('REACT_APP_BACKEND_URL')
  readonly CLIENT_ID = Config.dotenv('REACT_APP_CLIENT_ID')
  readonly FRONTEND = Config.dotenv('REACT_APP_FRONTEND_URL')
  readonly REDIRECT_URL = `${this.FRONTEND}/oauth`

  private static dotenv(env: string): string {
    const envvar = process.env[env]
    if (envvar == null || envvar === '') {
      if (process.env['NODE_ENV'] === 'test') {
        return 'NODE_ENV_TEST'
      }
      console.error(`[Config.${env}] Not set in .env`)
      throw new Error(`[Config.${env}] Not set in .env`)
    }
    return envvar
  }
}

const Cfg = new Config()
console.log(Cfg)

export default Cfg
