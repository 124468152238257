import React, { useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import Cfg from '../../app/Config'

export const Oauth: React.FC<RouteComponentProps> = p => {
  useEffect(() => {
    let result: any = {}
    let params = p.location?.search.substr(1).split('&')
    params?.forEach(it => {
      let [k, v] = it.split('=')
      result[k] = v
    })
    window.opener.postMessage(result, Cfg.FRONTEND)
    window.close()
  })
  return <div>Redirecting back to Reddit Eraser</div>
}
