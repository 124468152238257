export const sp = {
  xxs: 4,
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48
}

export const mx = {
  xxs: `0px ${sp.xs}px`,
  xs: `0px ${sp.xs}px`,
  sm: `0px ${sp.sm}px`,
  md: `0px ${sp.md}px`,
  lg: `0px ${sp.lg}px`,
  xl: `0px ${sp.xl}px`
}

export const my = {
  xxs: `0px ${sp.xs}px`,
  xs: `${sp.xs}px 0px`,
  sm: `${sp.sm}px 0px`,
  md: `${sp.md}px 0px`,
  lg: `${sp.lg}px 0px`,
  xl: `${sp.xl}px 0px`
}

export const sz = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 18,
  xl: 24
}

export const wt = {
  thin: 100,
  light: 300,
  reg: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  black: 900
}

export const c = {
  link: '#007bff',
  white: '#fff',
  red5: '#ff4d4f'
}
