import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

export const Notify = new (class {
  success(args: string | ArgsProps): void {
    notification.success(this.parseArgs(args))
  }
  info(args: string | ArgsProps): void {
    notification.info(this.parseArgs(args))
  }
  error(args: string | ArgsProps): void {
    notification.error(this.parseArgs(args))
  }
  warn(args: string | ArgsProps): void {
    notification.warn(this.parseArgs(args))
  }

  private parseArgs(args: string | ArgsProps) {
    if (typeof args === 'string') {
      args = { message: args } as ArgsProps
    }
    return args
  }
})()
