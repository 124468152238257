import { Post } from 'reddit-ts/src/types/models/Post.type'
import React, { useState } from 'react'
import { View } from '../../../../components/RNWrapper'
import { SearchHeader } from './SearchHeader/SearchHeader'
import { ScrollView } from 'react-native-web'
import { PostTable } from './PostTable'

export const SearchResults = (p: { posts: Post[] }) => {
  const [selected, setSelected] = useState<string[]>([])

  return (
    <View style={{ height: '100vh' }}>
      <SearchHeader selectedPosts={selected} />
      <ScrollView>
        <PostTable posts={p.posts} selected={selected} setSelected={setSelected} />
      </ScrollView>
    </View>
  )
}
