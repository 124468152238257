import { ComputedLinkedState, LinkedState } from '../../../utils/LinkedState'
import { Post } from '../../../model/Post'
import { Date_ } from '@aelesia/commons'
import API from '../../../services/API'
import { Modal } from 'antd'
import RedditController from '../../../app/reddit/RedditController'
import Nav from '../../../app/navigation/Nav'
import Path from '../../../app/navigation/Path'

export const Posts = new LinkedState<Post[]>([])
export const FilteredPosts = new LinkedState<Post[]>([])
export const UniqueSubreddits = new ComputedLinkedState(Posts, state =>
  Array.from(new Set(state.map(it => it.subreddit)))
)

export async function fetchPosts(username: string) {
  await API.search(username).then(data => {
    Posts.set(data)
    FilteredPosts.set(Posts.state())
  })
}

export function filterSubreddits(subreddits: string[]) {
  FilteredPosts.set(Posts.state().filter(it => subreddits.includes(it.subreddit)))
}

export function filterByDate(fromDate: Date, toDate: Date) {
  FilteredPosts.set(
    Posts.state().filter(it => {
      return Date_.isAfter(it.date, fromDate) && Date_.isBefore(it.date, toDate)
    })
  )
}

export function confirmDeletePosts(deleteList: string[]) {
  Modal.confirm({
    title: `Delete ${deleteList.length} posts`,
    content: 'This action cannot be undone',
    okType: 'danger',
    okText: 'Delete',
    onOk: () => {
      RedditController.get_token()
        .then(() => {
          Nav.href(Path.summary, deleteList)
        })
        .catch()
    }
  })
}
